
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import langSwitch from "../layout/components/lang-switch.vue";
import { mobileRulesEl } from "@/utils/verify";
const user = namespace("user");
const app = namespace("app");
@Component({
  components: { langSwitch }
})
export default class Login extends Vue {
  @user.Action login;
  @user.Action queryUserMenus;
  @user.Action queryUserResources;
  @user.Action getUserData;
  @user.Action setCurrentStore;
  @user.State userData;
  @user.Mutation setOrgCode;
  @user.Mutation setPermissionList;
  @user.State menuList;
  loginForm: any = { account: "", password: "" };
  loginLoading: boolean = false;
  get rules() {
    return {
      account: [
        {
          required: true,
          message: this.$t("login.username-empty-tips"),
          trigger: "blur"
        }
      ],
      password: [
        {
          required: true,
          message: this.$t("login.password-empty-tips"),
          trigger: "blur"
        }
      ]
    };
  }
  isServerError: boolean = false;
  serverErrorMsg: string = "";
  isShowStoreList: boolean = false;
  submitForm(refName) {
    (this.$refs[refName] as any).validate(valid => {
      if (valid) {
        this.loginLoading = true;
        this.login(this.loginForm).then(
          async data => {
            await this.getUserData();
            await this.queryUserMenus({
              menuParent: "op_root",
              resourceClass: "3"
            });
            this.queryUserResources().then(data => {
              let permitList = (data.data.list || [])
                .filter(item => {
                  return item.resourceType === "4";
                })
                .map(item => item.resourceCode);
              this.setPermissionList(permitList);
              let firstPath = this.getFirstRoutePath();
              if (firstPath) {
                this.$router.push(firstPath);
              } else {
                this.$message.error(this.$t("v210831.no-menu-permissions") as string);
                this.loginLoading = false;
              }
            });
          },
          data => {
            this.serverErrorMsg = data.message;
            this.isServerError = true;
            this.loginLoading = false;
          }
        );
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
  validateMobile(rule, value, callback) {
    mobileRulesEl(value, callback);
  }
  getFirstRoutePath() {
    let path = "";
    function f(arr) {
      if (path) return path;
      if (arr[0].pageUrl) {
        path = arr[0].pageUrl;
      } else {
        let oneArr = arr[0].children;
        for (let i = 0; i < oneArr.length; i++) {
          if (oneArr[0].pageUrl) {
            path = oneArr[0].pageUrl;
            return path;
          } else {
            f(oneArr[0].children);
          }
        }
      }
    }
    f(this.menuList);
    return path;
  }
  backLogin() {
    this.isShowStoreList = false;
    this.loginForm.account = "";
    this.loginForm.password = "";
    this.isServerError = false;
    this.serverErrorMsg = "";
  }
  created() {
    // this.getNavData();
  }
}
